import React from 'react';
import { Link } from 'gatsby';
import Button from 'components/Form/Button';
import { social } from 'constants/index.js';
import MarkdownPreview from '@uiw/react-markdown-preview';
import {
  StyledThankYou,
  StyledThankYouContainer,
  StyledThankYouActions,
  StyledThankYouSocial,
  StyledThankYouWrapper
} from './styled';
import IconTwitter from 'assets/images/social/icon-twitter-white.svg';
import IconLinkedIn from 'assets/images/social/icon-linkedin-white.svg';

const ThankYou = ({ thankYou, setThankYou, thankYouInfo }) => {
  const { twitter, linkedin } = social;
  const {
    thankYouTitle,
    thankYouContent,
    backToSiteText,
    toBlogText
  } = thankYouInfo;

  return (
    <StyledThankYou isVisible={thankYou}>
      <StyledThankYouWrapper>
        <StyledThankYouContainer>
          <h2>{thankYouTitle}</h2>

          <MarkdownPreview source={thankYouContent} />

          <StyledThankYouActions>
            <Button
              as={Link}
              to="/"
              title={`${backToSiteText}`}
              onClick={() => {
                setThankYou(false);
              }}
            >
              {backToSiteText}
            </Button>
            <Button
              as={Link}
              to="http://blog.ateliware.com"
              title={`${toBlogText}`}
              variant="red400-outline"
              onClick={() => {
                setThankYou(false);
              }}
            >
              {toBlogText}
            </Button>
          </StyledThankYouActions>
          <StyledThankYouSocial>
            <a
              href={twitter}
              title="Twitter ateliware"
              target="blank"
              rel="noopener noreferrer"
            >
              <img src={IconTwitter} alt="Twitter" />
            </a>
            <a
              href={linkedin}
              title="LinkedIn ateliware"
              target="blank"
              rel="noopener noreferrer"
            >
              <img src={IconLinkedIn} alt="Twitter" />
            </a>
          </StyledThankYouSocial>
        </StyledThankYouContainer>
      </StyledThankYouWrapper>
    </StyledThankYou>
  );
};

export default ThankYou;

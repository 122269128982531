import styled from 'styled-components';
import fonts from 'assets/global/fonts';
import colors from 'assets/global/colors';

export const StyledField = styled.div`
  display: block;
`;

export const StyledTextarea = styled.textarea`
  display: block;
  width: 100%;
  min-height: 50px;
  padding: 16px 24px;
  font-family: ${fonts.secondary};
  font-size: 14px;
  color: ${colors.gray300};
  border: 1px solid ${colors.gray100};

  border-radius: ${(props) => (props.rounded ? '8px' : '2px')};
  transition: all 0.4s ease-out;
  resize: none;
  outline: none;

  &:focus {
    border-color: ${colors.red400};
    border-radius: 2px;
  }
`;

import styled from 'styled-components';
import colors from 'assets/global/colors';

export const StyledForm = styled.form`
  display: block;
  width: 100%;
  padding-left: 30px;

  @media screen and (max-width: 980px) {
    padding-left: 0px;
  }
`;

export const StyledFormItem = styled.div`
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }
`;

export const StyledTerms = styled.div`
  display: block;
  margin-top: 10px;
  text-align: left;

  a {
    position: relative;

    font-weight: 400;
    color: ${colors.red400};
    text-decoration: none;
    transition: all 0.3s linear;

    &:before,
    &:after {
      position: absolute;
      bottom: 0;
      width: 0;
      height: 1px;
      background-color: ${colors.red400};
      transition: all 0.3s linear;
      content: '';
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }

    &:hover,
    &.category--active {
      &:before,
      &:after {
        width: 100%;
      }
    }
  }
`;

export const StyledFormError = styled.div`
  display: block;
  width: 100%;
  margin: 30px 0;
  padding: 10px;
  color: ${colors.white};
  text-align: center;
  background-color: ${colors.red300};
  border-radius: 2px;

  p {
    color: ${colors.white};
    padding: 4px 0;
    margin: 0;
    font-weight: 600;
  }
`;

export const nonOrganizationalEmails = [
  '@gmail.com',
  '@hotmail.com',
  '@live.com',
  '@outlook.com',
  '@icloud.com',
  '@yahoo.com',
  '@aol.com',
  '@ymail.com',
  '@terra.com',
  '@uol.com',
  '@globo.com',
  '@bol.com',
  '@zohomail.com',
  '@proton',
  '@gmx'
];

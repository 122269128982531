import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { useForm } from 'react-hook-form';
import Input from 'components/Form/Input';
// import Select from 'components/Form/Select';
import Textarea from 'components/Form/Textarea';
import Button from 'components/Form/Button';
import ButtonDisabled from 'components/Form/ButtonDisabled';
import Checkbox from 'components/Form/Checkbox';
import ThankYou from 'components/ThankYou';
import fetch from 'isomorphic-fetch';
import { gql, ApolloClient, InMemoryCache } from '@apollo/client';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { nonOrganizationalEmails } from './constants';

import {
  StyledForm,
  StyledFormItem,
  StyledTerms,
  StyledFormError
} from './styled';
import { Helmet } from 'react-helmet';

const client = new ApolloClient({
  uri:
    'https://api-eu-central-1.graphcms.com/v2/ckcnu9rhx3zcu01xs3nznaqpq/master',
  cache: new InMemoryCache(),
  headers: {
    authorization: `Bearer ${process.env.GATSBY_CMS_TOKEN}`
  },
  fetch
});

const capitalize = (str) => {
  if (typeof str !== 'string') {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.substr(1);
};

const FormContact = ({
  contactForm,
  baseURL,
  isLp,
  lang,
  rounded = false,
  withSubmitButtonIcon = true,
  withSubmitButtonBlock = false,
  conversionIdentifier = 'Envio formulário site'
}) => {
  useEffect(() => {
    let url_params = window.location.search;

    if (url_params.length > 0) {
      const splited_params = new URLSearchParams(url_params);

      const params = [
        'source',
        'medium',
        'campaign',
        'id',
        'term',
        'content'
      ];

      const extraParams = ['palavra'];

      params.forEach((param) => {
        let utm = splited_params.get(`utm_${param}`);
        if (utm !== null && utm.length > 0) {
          document.cookie = `utm${capitalize(param)}=${utm}`;
        }
      });

      extraParams.forEach((param) => {
        let extra = splited_params.get(param);
        if (extra !== null && extra.length > 0) {
          document.cookie = `${param}=${extra}`;
        }
      });
    }
  }, []);

  const {
    nameField,
    companyField,
    emailField,
    phoneField,
    aboutYouField,
    sendButtonText,
    formErrorMessage,
    privacyPolicyTitle,
    privacyPolicyText,
    thankYouTitle,
    thankYouContent,
    backToSiteText,
    toBlogText
  } = contactForm;
  var thankYouInfo = {
    thankYouTitle: thankYouTitle,
    thankYouContent: thankYouContent,
    backToSiteText: backToSiteText,
    toBlogText: toBlogText
  };

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [thankYou, setThankYou] = useState(false);
  const [formError, setFormError] = useState(false);
  const { handleSubmit, register, watch, errors } = useForm();
  const isTermsAccepted = watch('acceptTerms');

  const MutateQuery = gql`
    mutation CreateContactMutation($data: ContactCreateInput!) {
      createContact(data: $data) {
        timestampId
        firstName
        companyName
        emailAddress
        phoneNumber
        serviceType
        message
        budget
        utmMedium
        utmSource
        utmContent
        utmCampaign
        utmTerm
        utmPage
        isLp
        acceptTerms
        conversionIdentifier
        palavra
      }
    }
  `;

  const onSubmit = (data) => {
    let leadRedirect = false;

    const getCookie = (cookieName) => {
      var name = cookieName + '=';
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    };

    var formData = {
      timestampId: Date.now().toString(),
      firstName: data.firstName,
      lastName: data.lastName,
      companyName: data.companyName,
      emailAddress: data.emailAddress,
      phoneNumber: data.phoneNumber,
      serviceType: data.serviceType,
      message: data.message,
      budget: data.budget,
      acceptTerms: data.acceptTerms,
      utmMedium: getCookie('utmMedium'),
      utmSource: getCookie('utmSource'),
      utmContent: getCookie('utmContent'),
      utmCampaign: getCookie('utmCampaign'),
      utmTerm: getCookie('utmTerm'),
      utmPage: getCookie('referrerSource').split('?')[0],
      trackingId: getCookie('__ss_tk'),
      trafficSource: getCookie('__trf.src'),
      isLp: isLp,
      conversionIdentifier,
      palavra: getCookie('palavra')
    };

    if (
      nonOrganizationalEmails.some((email) =>
        data.emailAddress.includes(email)
      )
    ) {
      leadRedirect = true;
    }

    client
      .mutate({
        mutation: MutateQuery,
        variables: { data: formData }
      })
      .then((res) => {
        const dataLayer = window.dataLayer || [];

        dataLayer.push({ 'submit-form': 'success' });

        if (leadRedirect) {
          window.location.replace(
            'https://content.ateliware.com/qualificacao-de-leads/'
          );
        } else {
          setThankYou(true);
          setFormError(false);
        }
      })
      .catch((err) => {
        setFormError(true);

        setTimeout(() => {
          setFormError(false);
        }, 10000);
      });
  };
  return (
    <React.Fragment>
      <Helmet>
        <style type="text/css">
          {`
          .input-rounded-class {
            border-radius:8px !important;
            transition: all 0.4s ease-out !important;
          }
          `}
        </style>
      </Helmet>
      <StyledForm
        id="wf-form-email-ateliware"
        onSubmit={handleSubmit(onSubmit)}
      >
        <StyledFormItem>
          <Input
            rounded={rounded}
            type="text"
            name="firstName"
            placeholder={nameField}
            ref={register({
              required: true,
              validate: (value) =>
                /^\s*\S+\s+\S+/.test(value) ||
                'O nome completo precisa ter pelo menos duas palavras'
            })}
            required
          />
        </StyledFormItem>
        <StyledFormItem>
          <Input
            rounded={rounded}
            type="text"
            name="companyName"
            placeholder={companyField}
            ref={register({ required: true })}
            required
          />
        </StyledFormItem>
        <StyledFormItem>
          <Input
            rounded={rounded}
            type="email"
            name="emailAddress"
            placeholder={emailField}
            ref={register({ required: true })}
            required
          />
        </StyledFormItem>
        <StyledFormItem>
          <PhoneInput
            rounded={rounded}
            country={lang === 'pt' ? 'br' : 'us'}
            inputProps={{
              type: 'tel',
              name: 'phoneNumber',
              placeholder: phoneField,
              ref: register({ required: true })
            }}
            required
            inputClass={!rounded ? undefined : 'input-rounded-class'}
          />
        </StyledFormItem>
        {/* <StyledFormItem>
          <Select
            name="serviceType"
            placeholder={serviceOptionDefault}
            defaultValue={serviceOptionDefault}
            ref={register({ required: true })}
            required
          >
            <option value={''}>{serviceOptionDefault}</option>

            <option value={serviceOption1}>{serviceOption1}</option>
            <option value={serviceOption2}>{serviceOption2}</option>
            <option value={serviceOption3}>{serviceOption3}</option>
            <option value={serviceOption4}>{serviceOption4}</option>
          </Select>
        </StyledFormItem> */}
        <StyledFormItem>
          <Textarea
            rounded={rounded}
            name="message"
            placeholder={aboutYouField}
            ref={register({ required: true })}
            required
          />
        </StyledFormItem>
        {/* <StyledFormItem>
          <Select
            name="budget"
            placeholder={budgetDefault}
            defaultValue={budgetDefault}
            ref={register({ required: true })}
            required
          >
            <option value={''}>{budgetDefault}</option>

            <option value={budgetOption1}>{budgetOption1}</option>
            <option value={budgetOption2}>{budgetOption2}</option>
            <option value={budgetOption3}>{budgetOption3}</option>
          </Select>
        </StyledFormItem> */}
        <Input id="lastSourceAttribution" type="hidden" />
        <Input id="firstSourceAttribution" type="hidden" />
        <Input id="multiSourceAttribution" type="hidden" />
        <Input id="fbclid" type="hidden" />
        <Input id="utm_content" type="hidden" />
        <Input id="gclid" type="hidden" />
        <Input id="utm_campaign" type="hidden" />
        <Input id="utm_term" type="hidden" />
        <StyledTerms>
          <Checkbox
            name="acceptTerms"
            onClick={(event) => {
              if (event.target.value === true) {
                setTermsAccepted(!termsAccepted);
              }
            }}
            ref={register({ required: false })}
          >
            <p>
              {privacyPolicyText}{' '}
              <Link
                to={
                  lang === 'pt'
                    ? 'https://www.iubenda.com/privacy-policy/45453832'
                    : 'https://www.iubenda.com/privacy-policy/21746476'
                }
                target="_blank"
                title="Política de Privacidade"
              >
                {privacyPolicyTitle}
              </Link>
              .
            </p>
          </Checkbox>
        </StyledTerms>
        {formError && (
          <StyledFormError>
            <p>{formErrorMessage}</p>
          </StyledFormError>
        )}
        {Object.keys(errors).length > 0 && (
          <StyledFormError>
            <p>Formulário incompleto / Dados inválidos</p>
          </StyledFormError>
        )}
        <StyledFormItem>
          {isTermsAccepted ? (
            <Button
              id="contact-submit"
              type="submit"
              callToAction={true}
              width={!withSubmitButtonBlock ? undefined : '100%'}
              block={withSubmitButtonBlock}
              withIcon={withSubmitButtonIcon}
              onClick={(e) => {
                var phone = document.getElementsByName(
                  'phoneNumber'
                )[0];
                if (phone.value.length < 4) {
                  if (lang === 'pt') {
                    phone.setCustomValidity('Preencha este campo.');
                  } else {
                    phone.setCustomValidity('Field is empty.');
                  }
                } else {
                  phone.setCustomValidity('');
                }
              }}
            >
              {sendButtonText}
            </Button>
          ) : (
            <ButtonDisabled
              disabled
              width={!withSubmitButtonBlock ? undefined : '100%'}
              id="contact-submit"
              type="submit"
              callToAction={true}
              withIcon={withSubmitButtonIcon}
            >
              {sendButtonText}
            </ButtonDisabled>
          )}
        </StyledFormItem>
      </StyledForm>
      <ThankYou
        thankYou={thankYou}
        setThankYou={setThankYou}
        thankYouInfo={thankYouInfo}
      />
    </React.Fragment>
  );
};

export default FormContact;

import React from 'react';
import { StyledButtonDisabled } from './styled';

const ButtonDisabled = React.forwardRef(
  ({ children, callToAction, variant, ...props }, ref) => {
    return (
      <StyledButtonDisabled
        ref={ref}
        callToAction={callToAction}
        variant={variant}
        {...props}
      >
        {children}
      </StyledButtonDisabled>
    );
  }
);

ButtonDisabled.defaultProps = {
  callToAction: false,
  variant: 'primary'
};

export default ButtonDisabled;

import styled, { css } from 'styled-components';
import fonts from 'assets/global/fonts';
import colors from 'assets/global/colors';
import IconChecked from 'assets/images/icons/icon-checked.svg';

export const StyledCheck = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 26px;
  padding: 5px;
  margin-right: 16px;
  border: 2px solid ${colors.gray100};
  border-radius: 2px;

  &:after {
    display: none;
    content: '';
  }
`;

export const StyledCheckbox = styled.label`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin: 8px 0;
  font-family: ${fonts.primary};
  font-size: 14px;
  color: ${colors.gray300};
  text-decoration: none;
  transition: all 0.2s ease-out;
  cursor: pointer;
  user-select: none;

  input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ span {
    background-color: ${colors.red400};
  }

  ${StyledCheck}:after {
    width: 100%;
    height: 100%;
    background-image: url('${IconChecked}');
    background-repeat: no-repeat;
    background-size: 12px auto;
    background-position: center;
    content: '';
  }

  input:checked ~ ${StyledCheck}:after {
    display: block;
  }
`;

import React from 'react';
import { StyledField, StyledTextarea } from './styled';

const Textarea = React.forwardRef(({ ...props }, ref) => {
  return (
    <StyledField>
      <StyledTextarea ref={ref} {...props} />
    </StyledField>
  );
});

export default Textarea;

import React from 'react';
import { StyledCheckbox, StyledCheck } from './styled';

const Checkbox = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <StyledCheckbox>
      <input type="checkbox" ref={ref} {...props} />
      <StyledCheck />
      {children}
    </StyledCheckbox>
  );
});

export default Checkbox;

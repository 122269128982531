import styled, { css } from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';
import { StyledWrapper } from 'assets/global/styled';
import BackgroundThankYou from 'assets/images/thankyou/bg-thankyou.webp';

export const StyledThankYou = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100vh;
  background-color: ${colors.gray200};
  background-image: url('${BackgroundThankYou}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transform: scale3d(1.1, 1.1, 1.1);
  transition: all 0.4s ease-out;

  ${(props) =>
    props.isVisible &&
    css`
      opacity: 1;
      visibility: visible;
      transform: scale(1);
      transform: scale3d(1, 1, 1);
    `}

  ${StyledWrapper} {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`;

export const StyledThankYouContainer = styled.div`
  color: ${colors.white};
  text-align: center;

  h2 {
    position: relative;
    font-family: ${fonts.primary};
    font-size: 60px;
    color: ${colors.white};

    &:before,
    &:after {
      margin: 0 10px;
    }

    &:before {
      content: '[';
    }

    &:after {
      content: ']';
    }
  }

  p {
    font-size: 24px;
    color: ${colors.white};
    font-family: ${fonts.secondary};
  }
`;

export const StyledThankYouActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;

  button,
  a {
    margin: 10px;
  }
`;

export const StyledThankYouSocial = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;

  a {
    margin: 10px;

    img {
      width: 24px;
      height: auto;
    }
  }
`;

export const StyledThankYouWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 100%;
`;

import styled, { css } from 'styled-components';
import fonts from 'assets/global/fonts';
import colors from 'assets/global/colors';
import { lighten } from 'polished';

export const StyledButtonDisabled = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  font-family: ${fonts.primary};
  font-size: 14px;
  color: ${colors.white};
  text-decoration: none;
  border: 1px solid ${colors.gray100};
  width: ${(props) => props.width};
  border-radius: 2px;
  background-color: ${colors.gray100};
  transition: all 0.2s ease-out;
  outline: none;
  -webkit-appearance: none;

  ${(props) =>
    props.callToAction &&
    props.withIcon &&
    css`
      &:after {
        display: inline-block;
        margin-left: 10px;
        content: '→';
        font-size: 24px;
      }
    `}
`;
